import React from "react"
import Ticker from "react-ticker";

const main = {
    height: "calc(100vh - 15px)",
    width: "100vw",
    padding: "0",
    margin: "0",
    overflow: "hidden"
};

const NotFound = () => {
  return (
      <main style={main}>
          <Ticker>
              {() => (
                  <>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                      <p> 404 </p>
                  </>
              )}
          </Ticker>
      </main>
  );
}

export default NotFound